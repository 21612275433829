"use strict";

$(document).ready(function(){
    
    //show firstscreenImg when it is full loaded
    if($('#firstscreenImg').length) {
        $('#firstscreenImg').one("load", function() {
            $(this).addClass('is-loaded'); 
        }).each(function() {
            if(this.complete) {
                $(this).trigger('load'); 
            }
        });
    }

    //mainmenu func
    $(document).on('click', '.js-mainmenu-link', function(e){
        e.preventDefault();
        var ths = $(this),
            mainmenuLang = ths.attr('href'),
            dataSubmenu = ths.attr('data-submenu');

        $('.js-mainmenu.is-current').removeClass('is-current');
        $('html').addClass('is-fixed');
        $('body').addClass('is-fixed');
        $(mainmenuLang).addClass('is-current');
        
        if(mainmenuLang === "#mainmenuRu") {
            $('html').attr('lang', 'ru');
        } else {
            $('html').attr('lang', 'en');
        }

        $('.js-mainmenu .is-current').removeClass('is-current');
        $('.js-submenu[data-submenu='+dataSubmenu+']').addClass('is-current');
        $('.js-submenu-link[data-submenu='+dataSubmenu+']').addClass('is-current');
    });

    //submenu func
    $('.js-submenu-link').each(function(){
        var ths = $(this),
            dataSubmenu = ths.attr('data-submenu'),
            mainmenu = ths.closest('.js-mainmenu');

        ths.on('mouseenter', function(){
            mainmenu.find('.is-current').removeClass('is-current');
            mainmenu.find('.js-submenu[data-submenu='+ dataSubmenu +']').addClass('is-current');
            ths.addClass('is-current');
        });

        if(dataSubmenu === "0") {
            ths.on('mouseleave', function(){
                ths.removeClass('is-current');
            });
        }
    });

    //Click anywhere outside the element
	$('.mainmenu__content').on('click touchstart', function(e){
		if (!($(e.target).closest('.js-submenu').length || $(e.target).closest('.header').length || $(e.target).closest('.footer').length)) {
            $('html').removeClass('is-fixed');
            $('body').removeClass('is-fixed');
            $('.js-mainmenu.is-current').removeClass('is-current');
		}	
	});

    //jquery scrollbar
    if($('.scrollbar-macosx').length) {
        $('.scrollbar-macosx').scrollbar();
    }

    //callback form success message func
    $('.js-callback-wrap').each(function(){
        var ths = $(this),
            callbackForm = ths.find('.js-callback-form'),
            callbackSubmit = ths.find('.js-callback-submit'),
            callbackMsg = ths.find('.js-callback-msg'),
            callbackBack = ths.find('.js-callback-back');

        callbackSubmit.on('click', function(evt){
            evt.preventDefault(); // for demo only
            callbackForm.addClass('is-hidden');
            callbackMsg.addClass('is-visible');
        });

        callbackBack.on('click', function(evt){
            evt.preventDefault();
            callbackForm.removeClass('is-hidden');
            callbackMsg.removeClass('is-visible');
        });
    });


    //tabs func
    var tabsFunc = function(){
        $(document).on('click', '.js-tabs-link', function(e){
            if($(window).width() > 1007) {
                e.preventDefault();
                var ths = $(this),
                    dataTab = ths.attr('data-tab'),
                    wrap = ths.closest('.js-tabs-wrap'),
                    tabsMock = wrap.find('.js-tabs-mock');

                tabsMock.addClass('is-hidden');
                wrap.find('.current-tab').removeClass('current-tab');
                wrap.find('.js-tabs-content[data-tab='+ dataTab +']').addClass('current-tab');
                ths.addClass('current-tab');
            } 
        });
    }

    tabsFunc();

    $(window).on('resize', function(){
        tabsFunc();
    });

    //plan map func
    $(document).on('click touchstart', '.js-plan-number', function(e){
        e.preventDefault();
        
        var ths = $(this),
            dataNumber = ths.attr('data-number'),
            wrap = ths.closest('.js-plan');

        wrap.find('.current-number').removeClass('current-number');
        wrap.find('.js-plan-item[data-number='+ dataNumber +']').addClass('current-number');
        ths.addClass('current-number');
    });
    

    //switch func
    $(document).on('click', '.js-switch-link', function(e){
        e.preventDefault();
        var ths = $(this),
            dataSwitch = ths.attr('data-switch'),
            wrap = ths.closest('.js-switch-wrap');

        wrap.find('.current-switch').removeClass('current-switch');
        wrap.find('.js-switch-content[data-switch='+ dataSwitch +']').addClass('current-switch');
        ths.addClass('current-switch');
    });

    //calculate #planNumbers width depending on the #planImg width
    if($('#planImg').length) {
        $('#planImg').one("load", function() {
            $('#planNumbers').addClass('img-loaded'); 
            $('#planNumbers').css({ width: $('#planImg').outerWidth()});
        }).each(function() {
            if(this.complete) {
                $(this).trigger('load'); 
            }
        });

        $(window).on('resize', function(){
            $('#planNumbers').css({ width: $('#planImg').outerWidth()});
        })
    }

});

